<template>
  <div class="about">
    <transition name="right">
      <el-menu
        v-if="show"
        default-active="2"
        class="el-menu-vertical-demo"
        @select="handleSelect"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        <el-menu-item index="1">
          <span slot="title">Home</span>
        </el-menu-item>
        <el-menu-item index="2">
          <span slot="title">About Us</span>
        </el-menu-item>
        <el-menu-item index="3">
          <span slot="title">What We Do</span>
        </el-menu-item>
        <el-menu-item index="4">
          <span slot="title">Contact</span>
        </el-menu-item>
      </el-menu>
    </transition>
    <div class="about-pic"></div>
    <transition name="right">
      <div class="about-main" v-if="show">
        <div class="about-float">
          <h1>ABOUT US</h1>
          <p style="margin-bottom: 10px">
            HaagentiTech Inc., a dallas-based electronics distribution company.
            We mainly focus on cross-border international business trade and it
            mainly involves:
          </p>
          <p>
            • Distribution, after-sales, logistics planning, maintenance,
            inventory disposal, harmless disposal service of consumer electronic
            products;
          </p>
          <p>
            • We provide medical supplies, OTC prescription drugs, and raw
            materials as FDA-registered importer in the United States, including
            import and export services.
          </p>
        </div>
      </div>
    </transition>
    <transition name="right">
      <div class="homes-bottom" v-if="show" @click="nextWeb"></div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      show: false,
      showfont: false,
    };
  },
  methods: {
    handleSelect(key) {
      this.$emit("keyRoute", key);
    },
    nextWeb() {
      this.$emit("keyRoute", "3");
    },
  },
  mounted() {
    this.show = !this.show;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.right-enter,
.right-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.right-enter-to,
.right-leave {
  opacity: 1;
  transform: translateY(0px);
}
.right-enter-active,
.right-leave-active {
  transition: all 2s;
}
.about {
  background: url(../assets/about-bg.png) no-repeat center 100%;
  background-size: 100% 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.about-main {
  position: absolute;
  width: 900px;
  height: 600px;
  top: 50%;
  left: 48%;
  transform: translate(-50%, -50%);
  border: 8px solid #163973;
  background: url(../assets/linux.png) no-repeat center 100%;
  color: #fff;
  padding: 50px;
  .about-float {
    float: right;
  }
  p {
    font-family: "myFirstFont";
    width: 530px;
    text-align: left;
    font-size: 20px;
    line-height: 30px;
  }
  h1 {
    font-family: "myThridFont";
    width: 500px;
    text-align: right;
    font-size: 80px;
    margin: 30px 0;
    font-weight: 300;
  }
}
.about-pic {
  background: url(../assets/about-background.png) no-repeat center 100%;
  background-size: 100% 100%;
  width: 37%;
  height: 100%;
}
.el-menu-vertical-demo.el-menu {
  position: absolute;
  z-index: 11;
  background: transparent;
  right: 100px;
  bottom: 0;
  border: 0;
  text-align: left;
  border-left: 1px solid #164b9f;
  padding-bottom: 180px;
  .el-menu-item {
    color: #8a94af !important;
    font-size: 30px;
    font-family: "myFirstFont";
    margin-bottom: 60px;
    position: relative;
  }
  .el-menu-item.is-active::before {
    content: "";
    height: 100%;
    display: block;
    position: absolute;
    left: -8px;
    border-left: 8px solid #164b9f;
  }
  .el-menu-item.is-active {
    color: #fff !important;
  }
  .el-menu-item:focus,
  .el-menu-item:hover {
    background: transparent;
    color: #fff !important;
  }
}

.homes-bottom {
  display: none;
}

@media (max-width: 800px) {
  .homes-bottom {
    position: absolute;
    bottom: 3%;
    transform: translateX(-50%);
    left: 50%;
    width: 5vh;
    height: 5vh;
    background: url(../assets/next_arro.png) no-repeat center 100%;
    background-size: 100%;
    display: block;
  }

  .el-menu-vertical-demo {
    display: none;
  }

  .about-pic {
    background: url(../assets/about-background.png) no-repeat center 100%;
    background-size: 100% 100%;
    width: 100%;
    height: 67%;
  }

  .about-main {
    position: absolute;
    width: 90%;
    height: auto;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 8px solid #163973;
    background: url(../assets/linux.png) no-repeat center 100%;
    color: #fff;
    padding: 2vh;
    .about-float {
      width: calc(100% - 1vh);
    }
    p {
      font-family: "myFirstFont";
      width: 100%;
      text-align: left;
      font-size: 2vh;
      line-height: 3vh;
      word-wrap: break-word;
      word-break: normal;
    }
    h1 {
      font-family: "myThridFont";
      width: auto;
      text-align: right;
      font-size: 5vh;
      margin: 0;
      font-weight: 300;
    }
  }
}
</style>
