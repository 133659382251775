<template>
  <div class="homes">
    <transition name="fonts">
      <div class="logo-font" v-if="showfont">
        SMART SOLUTION AND RELIABLE PARTNER
      </div>
    </transition>
    <div class="logo-center"></div>
    <div class="logo-center-main"></div>
    <div class="logo-center-circual"></div>
    <transition name="left">
      <div class="homes-left" v-if="show">
        <div class="homes-left-phone homes-left-div">
          <p>1-682-802-9837 (Jason)</p>
        </div>
        <div class="homes-left-email homes-left-div">
          <p>jasonjia@Haagentitech.com</p>
        </div>
        <div class="homes-left-position homes-left-div">
          <p>2755 Miller Park N Suite 200, Garland. TX 75042</p>
        </div>
      </div>
    </transition>
    <transition name="right">
      <el-menu
        v-if="show"
        default-active="1"
        class="el-menu-vertical-demo"
        @select="handleSelect"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        <el-menu-item index="1">
          <span slot="title">Home</span>
        </el-menu-item>
        <el-menu-item index="2">
          <span slot="title">About Us</span>
        </el-menu-item>
        <el-menu-item index="3">
          <span slot="title">What We Do</span>
        </el-menu-item>
        <el-menu-item index="4">
          <span slot="title">Contact</span>
        </el-menu-item>
      </el-menu>
    </transition>
    <transition name="right">
      <div class="homes-bottom" v-if="show" @click="nextWeb"></div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      show: false,
      showfont: false,
    };
  },
  methods: {
    handleSelect(key) {
      this.$emit("keyRoute", key);
    },
    nextWeb() {
      this.$emit("keyRoute", "2");
    },
  },
  mounted() {
    setTimeout(() => {
      this.show = !this.show;
    }, 3000);
    setTimeout(() => {
      this.showfont = !this.showfont;
    }, 2000);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.left-enter,
.left-leave-to {
  opacity: 0;
  margin-top: 30px;
}
.left-enter-to,
.left-leave {
  opacity: 1;
  margin-top: 0px;
}
.left-enter-active,
.left-leave-active {
  transition: all 2s;
}
.right-enter,
.right-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.right-enter-to,
.right-leave {
  opacity: 1;
  transform: translateY(0px);
}
.right-enter-active,
.right-leave-active {
  transition: all 2s;
}
.fonts-enter,
.fonts-leave-to {
  opacity: 0;
}
.fonts-enter-to,
.fonts-leave {
  opacity: 1;
}
.fonts-enter-active,
.fonts-leave-active {
  transition: all 2s;
}
.homes-bottom {
  position: absolute;
  bottom: 40px;
  transform: translateX(-50%);
  left: 50%;
  width: 22px;
  height: 35px;
  cursor: pointer;
  background: url(../assets/next.png) no-repeat center 100%;
}
.homes-left {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
  p {
    display: none;
    color: #fff;
    font-family: "mySecondFont";
    position: absolute;
    white-space: nowrap;
    font-size: 25px;
    top: 50%;
    transform: translateY(-50%);
    left: 80px;
  }
}
.homes-left-div {
  position: relative;
}
.homes-left-phone {
  width: 61px;
  height: 61px;
  margin-bottom: 20px;
  background: url(../assets/phone.png) no-repeat center 100%;
  p {
  }
}
.homes-left-email {
  width: 61px;
  height: 61px;
  margin-bottom: 20px;
  background: url(../assets/email.png) no-repeat center 100%;
}
.homes-left-position {
  width: 61px;
  height: 61px;
  margin-bottom: 20px;
  background: url(../assets/position.png) no-repeat center 100%;
}
.homes-left-phone:hover p {
  display: block;
}
.homes-left-phone:hover {
  background: url(../assets/phone1.png) no-repeat center 100%;
}
.homes-left-email:hover p {
  display: block;
}
.homes-left-email:hover {
  background: url(../assets/email1.png) no-repeat center 100%;
}
.homes-left-position:hover p {
  display: block;
}
.homes-left-position:hover {
  background: url(../assets/position1.png) no-repeat center 100%;
}
.homes {
  background: url(../assets/background.png) no-repeat center 100%;
  background-size: 100% 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.logo-font {
  position: absolute;
  width: 100%;
  font-family: "myThridFont";
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  top: 75%;
}
.logo-center {
  position: absolute;
  width: 100%;
  height: 100%;
  // background: url(../assets/center.png) no-repeat center;
  background-size: 44%;
}
.logo-center-main {
  position: absolute;
  width: 500px;
  height: 500px;
  top: 45%;
  left: 50%;
  margin-top: -250px;
  margin-left: -250px;
  background: url(../assets/li.png) no-repeat center;
  // background-size: 44%;
}
.logo-center-circual {
  position: absolute;
  width: 500px;
  height: 500px;
  top: 45%;
  left: 50%;
  margin-top: -250px;
  margin-left: -250px;
  animation: turn 2s linear 1;
  background: url(../assets/logo-circual.png) no-repeat center;
}

@keyframes turn {
  0% {
    -webkit-transform: rotate(0deg);
  }
  60% {
    -webkit-transform: rotate(-360deg);
  }
  65% {
    -webkit-transform: rotate(-363deg);
  }
  70% {
    -webkit-transform: rotate(-365deg);
  }
  80% {
    -webkit-transform: rotate(-370deg);
  }
  85% {
    -webkit-transform: rotate(-365deg);
  }
  90% {
    -webkit-transform: rotate(-363deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
.el-menu-vertical-demo.el-menu {
  position: absolute;
  z-index: 11;
  background: transparent;
  right: 100px;
  bottom: 0;
  border: 0;
  text-align: left;
  border-left: 1px solid #164b9f;
  padding-bottom: 280px;
  .el-menu-item {
    color: #8a94af !important;
    font-size: 30px;
    font-family: "myFirstFont";
    margin-bottom: 60px;
    position: relative;
  }
  .el-menu-item.is-active::before {
    content: "";
    height: 100%;
    display: block;
    position: absolute;
    left: -8px;
    border-left: 8px solid #164b9f;
  }
  .el-menu-item.is-active {
    color: #fff !important;
  }
  .el-menu-item:focus,
  .el-menu-item:hover {
    background: transparent;
    color: #fff !important;
  }
}

@media (max-width: 800px) {
  .logo-font {
    position: absolute;
    width: 100%;
    font-family: "myThridFont";
    font-size: 3.5vw;
    color: #fff;
    font-weight: 700;
    top: 55%;
  }
  .logo-center-main {
    position: absolute;
    width: 50%;
    height: 50%;
    top: 30%;
    left: 50%;
    margin-top: -25%;
    margin-left: -25%;
    background-size: 40%;
  }
  .logo-center-circual {
    position: absolute;
    width: 50%;
    height: 50%;
    top: 30%;
    left: 50%;
    margin-top: -25%;
    margin-left: -25%;
    background-size: 80%;
  }
  .logo-center {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(../assets/center.png) no-repeat center 25%;
    background-size: 80%;
  }
  .homes-left-div {
    position: initial;
  }
  .homes-left {
    position: absolute;
    top: 85%;
    transform: translateX(-50%);
    left: 50%;
    display: flex;
    justify-content: space-around;
    width: 50%;
    p {
      display: none;
      color: #fff;
      font-family: "mySecondFont";
      position: absolute;
      white-space: nowrap;
      font-size: 2vh;
      top: -100%;
    }
  }
  .homes-left-phone {
    width: 5vh;
    height: 5vh;
    margin-bottom: 0px;
    background-size: 100%;
    P {
      transform: translateX(-50%);
      left: 50%;
    }
  }
  .homes-left-email {
    width: 5vh;
    height: 5vh;
    margin-bottom: 0px;
    background-size: 100%;
    P {
      transform: translateX(-50%);
      left: 50%;
    }
  }
  .homes-left-position {
    width: 5vh;
    height: 5vh;
    margin-bottom: 0px;
    background-size: 100%;
    P {
      transform: translateX(-50%);
      left: 50%;
    }
  }

  .homes-left-phone:hover {
    background-size: 100%;
  }

  .homes-left-email:hover {
    background-size: 100%;
  }

  .homes-left-position:hover {
    background-size: 100%;
  }

  .homes-bottom {
    position: absolute;
    bottom: 3%;
    transform: translateX(-50%);
    left: 50%;
    width: 5vh;
    height: 5vh;
    background: url(../assets/next_arro.png) no-repeat center 100%;
    background-size: 100%;
  }

  .el-menu-vertical-demo {
    display: none;
  }
}
</style>
