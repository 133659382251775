<template>
  <div class="contact">
    <transition name="right">
      <el-menu
        v-if="show"
        default-active="4"
        class="el-menu-vertical-demo"
        @select="handleSelect"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        <el-menu-item index="1">
          <span slot="title">Home</span>
        </el-menu-item>
        <el-menu-item index="2">
          <span slot="title">About Us</span>
        </el-menu-item>
        <el-menu-item index="3">
          <span slot="title">What We Do</span>
        </el-menu-item>
        <el-menu-item index="4">
          <span slot="title">Contact</span>
        </el-menu-item>
      </el-menu>
    </transition>
    <transition name="left">
      <div class="contact-main" v-if="show">
        <p class="contact-main-tittle">CONTACT</p>
        <div>
          <div class="homes-left-phone homes-left-div">
            <p>1-682-802-9837 (Jason)</p>
          </div>
          <div class="homes-left-email homes-left-div">
            <p class="homes-left-email-p1">
              Job opportunity: dior@Haagentitech.com
            </p>
            <p class="homes-left-email-p2">
              Business Development: jasonjia@Haagentitech.com
            </p>
          </div>
          <div class="homes-left-position homes-left-div">
            <p>2755 Miller Park N Suite 200, Garland. TX 75042</p>
          </div>
          <el-popover placement="top" popper-class="pop-up" ref="popover">
            <p>
              ACCOUNTANT. Haageatt Tech Inc. Garland, TX. Master or its
              equivalent in Accounting or Business Administration in Accounting
              or Finance with 3.0 credit hours in Accounting-Financial Reporting
              course. Plus 3 years as Accountant. Or, Bachelor or its equivalent
              in Accounting or Business Administration in Accounting or Finance
              with 3.0 credit hours in Accounting-Financial Reporting course.
              Plus 5 years as Accountant. Send resume to dior@Haagentitech.com
            </p>
          </el-popover>
          <div class="homes-left-div click-here">``
            <p v-popover:popover>Recruitment</p>
          </div>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3349.6759982327353!2d-96.67377788443734!3d32.906733684666335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c1f482f142c37%3A0x250670f4896737be!2sHaagentiTech%20Inc.!5e0!3m2!1szh-CN!2shk!4v1606133952874!5m2!1szh-CN!2shk"
          width="750"
          height="580"
          frameborder="0"
          style="border: 0"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
          class="maps-contact"
        ></iframe>
      </div>
    </transition>
    <transition name="right">
      <div class="homes-bottom" v-if="show" @click="nextWeb"></div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      show: false,
      showfont: false,
      visible: false,
    };
  },
  methods: {
    handleSelect(key) {
      this.$emit("keyRoute", key);
    },
    nextWeb() {
      this.$emit("keyRoute", "33");
    },
  },
  mounted() {
    this.show = !this.show;
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.homes-bottom {
  display: none;
}
.maps-contact {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.homes-left-div.click-here {
  margin-top: 50px;
  p {
    font-weight: 700;
    left: 0;
    font-size: 30px;
    cursor: pointer;
    color: rgb(207, 205, 205);
    text-decoration: underline;
    font-style: italic;
  }
  p:hover {
    color: #fff;
  }
}
.homes-left-div {
  position: relative;
  p {
    color: #fff;
    font-family: "mySecondFont";
    position: absolute;
    white-space: nowrap;
    font-size: 25px;
    top: 50%;
    transform: translateY(-50%);
    left: 80px;
  }
  .homes-left-email-p1 {
    transform: translateY(-35px);
  }
  .homes-left-email-p2 {
    transform: translateY(0px);
  }
}
.homes-left-phone {
  width: 61px;
  height: 61px;
  margin-bottom: 20px;
  background: url(../assets/phone.png) no-repeat center 100%;
}
.homes-left-email {
  width: 61px;
  height: 61px;
  margin-bottom: 20px;
  background: url(../assets/email.png) no-repeat center 100%;
}
.homes-left-position {
  width: 61px;
  height: 61px;
  margin-bottom: 20px;
  background: url(../assets/position.png) no-repeat center 100%;
}
.contact-main {
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  left: 80px;
  width: 1500px;
  height: 580px;
  background: url(../assets/contact-font.png) no-repeat left 100%;
  padding: 70px;
  box-sizing: border-box;
  .contact-main-tittle {
    color: #fff;
    font-family: "myThridFont";
    text-align: left;
    font-size: 70px;
    margin-bottom: 20px;
    font-weight: 700;
    margin-bottom: 50px;
  }
}
.contact {
  background: url(../assets/contact-bg.png) no-repeat center 100%;
  background-size: 100% 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.left-enter,
.left-leave-to {
  opacity: 0;
  margin-top: 30px;
}
.left-enter-to,
.left-leave {
  opacity: 1;
  margin-top: 0px;
}
.left-enter-active,
.left-leave-active {
  transition: all 2s;
}
.right-enter,
.right-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.right-enter-to,
.right-leave {
  opacity: 1;
  transform: translateY(0px);
}
.right-enter-active,
.right-leave-active {
  transition: all 2s;
}
.el-menu-vertical-demo.el-menu {
  position: absolute;
  z-index: 11;
  background: transparent;
  right: 100px;
  bottom: 0;
  border: 0;
  text-align: left;
  border-left: 1px solid #164b9f;
  padding-bottom: 180px;
  .el-menu-item {
    color: #8a94af !important;
    font-size: 30px;
    font-family: "myFirstFont";
    margin-bottom: 60px;
    position: relative;
  }
  .el-menu-item.is-active::before {
    content: "";
    height: 100%;
    display: block;
    position: absolute;
    left: -8px;
    border-left: 8px solid #164b9f;
  }
  .el-menu-item.is-active {
    color: #fff !important;
  }
  .el-menu-item:focus,
  .el-menu-item:hover {
    background: transparent;
    color: #fff !important;
  }
}

@media (max-width: 800px) {
  .homes-left-div.click-here {
    margin-top: 3vh;
    p {
      font-size: 2vh;
    }
  }
  .homes-left-phone {
    width: 5vh;
    height: 5vh;
    margin-bottom: 3vh;
    background-size: 100%;
  }
  .homes-left-email {
    width: 5vh;
    height: 5vh;
    margin-bottom: 3vh;
    background-size: 100%;
  }
  .homes-left-position {
    width: 5vh;
    height: 5vh;
    margin-bottom: 3vh;
    background-size: 100%;
  }
  .homes-bottom {
    position: absolute;
    bottom: 3%;
    transform: translateX(-50%);
    left: 50%;
    width: 5vh;
    height: 5vh;
    background: url(../assets/pro-button.png) no-repeat center 100%;
    background-size: 100%;
    display: block;
  }

  .el-menu-vertical-demo {
    display: none;
  }
  .homes-left-div {
    p {
      white-space: nowrap;
      font-size: 1.5vh;
      top: 50%;
      left: 8vh;
    }
  }
  .contact-main {
    position: absolute;
    top: 55vh;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    height: 100%;
    background: none;
    padding: 10%;
    box-sizing: border-box;
    .contact-main-tittle {
      color: #fff;
      font-family: "myThridFont";
      text-align: left;
      font-size: 5vh;
      margin-bottom: 1vh;
      font-weight: 300;
      margin-bottom: 3vh;
    }
  }
  .contact {
    background: url(../assets/contact-font.png) no-repeat center 100%;
    background-size: 100% 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  .maps-contact{
    width: 90% !important;
    height: 42vh !important;
    left: 5%;
    top: 45%;
    transform: none;
  }
}
</style>
