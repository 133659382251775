export default [
    require('../assets/about-background.png'),
    require('../assets/about-bg.png'),
    require('../assets/banshou-active.png'),
    require('../assets/background.png'),
    require('../assets/banshou.png'),
    require('../assets/bg-active.png'),
    require('../assets/center.png'),
    require('../assets/com-active.png'),
    require('../assets/com.png'),
    require('../assets/contact-bg.png'),
    require('../assets/contact-font.png'),
    require('../assets/customer.png'),
    require('../assets/dan-bg.png'),
    require('../assets/email.png'),
    require('../assets/email1.png'),
    require('../assets/endof.png'),
    require('../assets/img-1.png'),
    require('../assets/img-2.png'),
    require('../assets/img-3.png'),
    require('../assets/img-4.png'),
    require('../assets/img-5.png'),
    require('../assets/img-6.png'),
    require('../assets/img-7.png'),
    require('../assets/img-8.png'),
    require('../assets/jiantou.png'),
    require('../assets/li.png'),
    require('../assets/linux.png'),
    require('../assets/logo-circual.png'),
    require('../assets/logo-header.png'),
    require('../assets/medical-bg.png'),
    require('../assets/medical-font-bg.png'),
    require('../assets/next.png'),
    require('../assets/phone.png'),
    require('../assets/phone1.png'),
    require('../assets/position.png'),
    require('../assets/position1.png'),
    require('../assets/resale.png'),
    require('../assets/right-header.png'),
    require('../assets/shen-bg.png'),
    require('../assets/testing.png'),
    require('../assets/wedo-bg.png'),
    require('../assets/yiliao-active.png'),
    require('../assets/yiliao.png'),
];