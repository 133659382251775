<template>
  <div class="home">
    <div class="header-all">
      <div class="right-header"></div>
      <div
        :class="keys !== '5' ? 'right-header-list' : 'right-header-list-close'"
        @click="chooseList('5')"
      ></div>
    </div>
    <home @keyRoute="keyChoose" v-if="keys === '1'" />
    <about @keyRoute="keyChoose" v-if="keys === '2'" />
    <wedo @keyRoute="keyChoose" :keyCom="chooseCom" v-if="keys === '3'" />
    <contact @keyRoute="keyChoose" v-if="keys === '4'" />
    <menuList @keyRoute="keyChoose" :oldKey="oldKey" v-if="keys === '5'" />
  </div>
</template>

<script>
// @ is an alias to /src
import home from "@/components/Home.vue";
import about from "@/components/About.vue";
import wedo from "@/components/Wedo.vue";
import contact from "@/components/Contact.vue";
import menuList from "@/components/Menu.vue";

export default {
  name: "Home",
  components: {
    home,
    about,
    wedo,
    contact,
    menuList,
  },
  data() {
    return {
      keys: "1",
      oldKey: "",
      chooseCom: "1",
      chooseComOld: "",
    };
  },
  mounted() {},
  methods: {
    keyChoose: function (value) {
      // console.log(value)
      if (value == "31") {
        this.chooseCom = "1";
        this.keys = "3";
        this.chooseComOld = "31";
      } else if (value == "32") {
        this.keys = "3";
        this.chooseCom = "2";
        this.chooseComOld = "32";
      } else if (value == "33") {
        this.keys = "3";
        this.chooseCom = "3";
        this.chooseComOld = "33";
      } else {
        this.keys = value.toString();
        this.chooseComOld = "";
      }
    },
    chooseList: function (value) {
      if (this.keys === "5") {
        if (this.oldKey == "31") {
          this.keys = "3";
          this.chooseCom = "1";
        } else if (this.oldKey == "32") {
          this.keys = "3";
          this.chooseCom = "2";
        } else if (this.oldKey == "33") {
          this.keys = "3";
          this.chooseCom = "3";
        } else {
          this.keys = this.oldKey;
        }
      } else {
        if (this.chooseComOld == "31") {
          this.oldKey = "31";
          this.keys = value;
        } else if (this.chooseComOld == "32") {
          this.oldKey = "32";
          this.keys = value;
        } else if (this.chooseComOld == "33") {
          this.oldKey = "33";
          this.keys = value;
        } else {
          this.oldKey = this.keys;
          this.keys = value;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  // background: url(../assets/background.png) no-repeat center 100%;
  height: 100%;
}

.header-all {
  position: fixed;
  top: 10px;
  background: url(../assets/logo-header.png) no-repeat 20px center;
  height: 72px;
  background-size: 240px;
  width: 100%;
  z-index: 10;
  .right-header {
    background: url(../assets/right-header.png) no-repeat center;
    height: 30px;
    width: 30px;
    float: right;
    margin-right: 20px;
    margin-top: 20px;
    display: block;
  }
  .right-header-list,
  .right-header-list-close {
    display: none;
  }
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
@media (max-width: 800px) {
  .header-all {
    position: fixed;
    top: 1%;
    background: url(../assets/logo-header.png) no-repeat 6% center;
    height: 9%;
    background-size: 35%;
    width: 100%;
    z-index: 10;
    .right-header-list {
      background: url(../assets/list_button.png) no-repeat center;
      height: 100%;
      width: 12%;
      background-size: 40%;
      float: right;
      margin-right: -0.5%;
      margin-top: -0.5%;
      display: block;
    }
    .right-header-list-close {
      background: url(../assets/close_list.png) no-repeat center;
      height: 100%;
      width: 12%;
      background-size: 40%;
      float: right;
      margin-right: -0.5%;
      margin-top: -0.5%;
      display: block;
    }
    .right-header {
      display: none;
    }
  }
}
</style>
