<template>
  <div class="contact">
    <transition name="right">
      <el-menu
        v-if="show"
        :default-active="oldKey"
        class="el-menu-vertical-demo"
        @select="handleSelect"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        <el-menu-item index="1">
          <span slot="title">Home</span>
        </el-menu-item>
        <el-menu-item index="2">
          <span slot="title">About Us</span>
        </el-menu-item>
        <el-menu-item index="31">
          <span slot="title">What We Do - Consumer Electronics Distribution</span>
        </el-menu-item>
        <el-menu-item index="32">
          <span slot="title">What We Do - Reverse Logistic Omni Solution</span>
        </el-menu-item>
        <el-menu-item index="33">
          <span slot="title">What We Do - Consuming Medical devices</span>
        </el-menu-item>
        <el-menu-item index="4">
          <span slot="title">Contact</span>
        </el-menu-item>
      </el-menu>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    oldKey: String
  },
  data() {
    return {
      show: false,
      showfont: false,
      visible: false,
      keys: ''
    };
  },
  methods: {
    handleSelect(key) {
      this.$emit("keyRoute", key);
    },
  },
  mounted() {
    this.show = !this.show;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.contact {
  background: url(../assets/background_menu.png) no-repeat center 100%;
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.right-enter,
.right-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.right-enter-to,
.right-leave {
  opacity: 1;
  transform: translateY(0px);
}
.right-enter-active,
.right-leave-active {
  transition: all 2s;
}
.el-menu-vertical-demo.el-menu {
  position: absolute;
  z-index: 11;
  background: transparent;
  left: 5%;
  top: 10%;
  border: 0;
  text-align: left;
  // border-left: 1px solid #164b9f;
  .el-menu-item {
    color: #8a94af !important;
    font-size: 1.8vh;
    font-family: "myFirstFont";
    margin-bottom: 3vh;
    position: relative;
    padding-left: 2% !important;
  }
  .el-menu-item.is-active::before {
    content: "";
    height: 100%;
    display: block;
    position: absolute;
    left: -8px;
    border-left: 8px solid #164b9f;
  }
  .el-menu-item.is-active {
    color: #fff !important;
  }
  .el-menu-item:focus,
  .el-menu-item:hover {
    background: transparent;
    color: #fff !important;
  }
}
</style>
