<template>
  <div class="wedo">
    <transition name="right">
      <el-menu
        v-if="show"
        default-active="3"
        class="el-menu-vertical-demo"
        @select="handleSelect"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        <el-menu-item index="1">
          <span slot="title">Home</span>
        </el-menu-item>
        <el-menu-item index="2">
          <span slot="title">About Us</span>
        </el-menu-item>
        <el-menu-item index="3">
          <span slot="title">What We Do</span>
        </el-menu-item>
        <el-menu-item index="4">
          <span slot="title">Contact</span>
        </el-menu-item>
      </el-menu>
    </transition>
    <transition name="left">
      <ul class="wedo-left" v-if="show">
        <li
          :class="[{ active: isActive === 1 }, 'wedo-left-1']"
          @click="handleClick(1)"
        >
          <div></div>
          <p>Consumer Electronics Distribution</p>
        </li>
        <li
          :class="[{ active: isActive === 2 }, 'wedo-left-2']"
          @click="handleClick(2)"
        >
          <div></div>
          <p>Reverse Logistic Omni Solution</p>
        </li>
        <li
          :class="[{ active: isActive === 3 }, 'wedo-left-3']"
          @click="handleClick(3)"
        >
          <div></div>
          <p>Consuming Medical devices</p>
        </li>
      </ul>
    </transition>
    <p class="wedo-main-font">WHAT WE DO</p>
    <transition name="left">
      <div class="wedo-main" v-if="isActive === 1">
        <p class="wedo-main-tittle">
          Consumer Electronics Distribution online/offline with channels and
          grocery stores like Walmart, BestBuy, Target, Costco.
        </p>
        <ul class="wedo-main-neirong">
          <li class="wedo-dan">
            <div></div>
            <p>Desktop/Laptop</p>
          </li>
          <li>
            <div></div>
            <p>Tablets</p>
          </li>
          <li class="wedo-dan">
            <div></div>
            <p>Cell phone</p>
          </li>
          <li>
            <div></div>
            <p>Smart Watch</p>
          </li>
          <li>
            <div></div>
            <p>Gaming Console and Accessories Play Station/Switch/Xbox</p>
          </li>
          <li class="wedo-dan">
            <div></div>
            <p>Robot/VR Helmet/Camera/Toys</p>
          </li>
          <li>
            <div></div>
            <p>Telecom</p>
          </li>
          <li class="wedo-dan">
            <div></div>
            <p>Servers/Switches/Router</p>
          </li>
        </ul>
      </div>
    </transition>
    <transition name="left">
      <div class="wedo-main-2" v-if="isActive === 2">
        <p class="wedo-main-tittle">
          Products retire from market and be processed to tear down into parts.
          Products end-of-life services and Beyond Economy Repair services.
        </p>
        <div class="wedo-main-2-main">
          <div class="wedo-main-2-main-1">
            <p class="wedo-main-2-main-right">Customer return</p>
            <div class="wedo-main-2-main-left">
              <p>• Online(customer return)</p>
              <P>• Off-line(distrubutor and customer return)</P>
            </div>
          </div>
          <div class="wedo-main-2-main-2">
            <p class="wedo-main-2-main-right">Testing</p>
            <div class="wedo-main-2-main-left-1">
              <p>• Fully Functional Check</p>
            </div>
          </div>
          <div class="wedo-main-2-main-3">
            <div class="wedo-main-2-main-3-1">
              <p class="wedo-main-2-main-right">Re-sale</p>
            </div>
            <div class="wedo-main-2-main-3-2">
              <p class="wedo-main-2-main-right-1">End-Of-Life Process</p>
              <div class="wedo-main-2-main-left-2">
                <p>
                  • Sorting by different materials like metal, plastic, circuit
                  board, glass
                </p>
                <p>
                  • Send final materials to different Downstream Clients to
                  maximize recycle and reuse value
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="left">
      <div class="wedo-main-3" v-if="isActive === 3">
        <div class="wedo-main-3-bg"></div>
        <div class="wedo-main-3-font">
          <p class="tittle-main-3x">
            FDA registered and certified importer of personal protective
            equipment.
          </p>
          <p class="wedo-main-3-font-1">Personal Protective Equipment</p>
          <p>• Masks: N95/KN95/3 Layer Disposable Mask</p>
          <p>• Gloves: Disposable Nitrile Gloves</p>
          <p>• Hand Sanitizer and Dispenser (Table/Wall Mount/Stand-Floor)</p>
          <p>• Tyvek Protective Suit</p>
          <p>• Antibacterial Wipe</p>
          <p class="wedo-main-3-font-1">
            API Active Pharmaceutical Ingredients Importer
          </p>
          <p>• Vitamin B1 B6 B12/Vitamin C</p>
          <p>• Tinidazole/Paracetamol/Acyclovir/Metronidazole</p>
        </div>
      </div>
    </transition>
    <transition name="right">
      <div class="homes-bottom" v-if="show" @click="nextWeb"></div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    keyCom: String,
  },
  data() {
    return {
      show: false,
      showfont: false,
      isActive: 0,
    };
  },
  methods: {
    handleSelect(key) {
      this.$emit("keyRoute", key);
    },
    handleClick(key) {
      this.isActive = key;
    },
    nextWeb() {
      if (this.isActive == 1) {
        this.isActive = 2;
      } else if (this.isActive == 2) {
        this.isActive = 3;
      } else {
        this.$emit("keyRoute", "4");
      }
    },
  },
  mounted() {
    this.show = !this.show;
    this.isActive = Number(this.keyCom);
    // console.log(this.chooseCom)
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.tittle-main-3x{
  font-size: 19px;
}
.wedo-main-3-bg {
  width: 1090px;
  height: 700px;
  background: url(../assets/medical-bg.png) no-repeat center 100%;
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  right: 0;
}
.wedo-main-3-font-1 {
  font-family: "mySecondFont";
  margin-top: 20px;
}
.wedo-main-3-font {
  width: 710px;
  height: 480px;
  background: url(../assets/medical-font-bg.png) no-repeat center 100%;
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  left: 340px;
  font-family: "myFirstFont";
  text-align: left;
  color: #fff;
  padding: 50px 40px;
  box-sizing: border-box;
  font-size: 20px;
  line-height: 30px;
}
.wedo-main-3 {
  // position: absolute;
  // top: 60%;
  // transform: translateY(-50%);
  // left: 340px;
  // color: #fff;
  // font-family: "myFirstFont";
}
.wedo-main-2 {
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  left: 340px;
  color: #fff;
  font-family: "myFirstFont";
  .wedo-main-tittle {
    color: #fff;
    font-family: "myFirstFont";
    text-align: left;
    font-size: 18px;
    margin-bottom: 20px;
  }
  .wedo-main-2-main {
    width: 1200px;
    height: 500px;
    .wedo-main-2-main-right {
      float: left;
      height: 100%;
      text-align: left;
      font-size: 24px;
      margin-left: 40px;
      line-height: 110px;
      width: 450px;
    }
    .wedo-main-2-main-right-1 {
      text-align: left;
      font-size: 24px;
      margin-left: 40px;
      line-height: 110px;
      width: 450px;
    }
    .wedo-main-2-main-left {
      float: left;
      text-align: left;
      font-size: 20px;
      height: 100%;
      padding: 10px 0;
      p {
        line-height: 45px;
      }
    }
    .wedo-main-2-main-left-1 {
      float: left;
      text-align: left;
      font-size: 20px;
      height: 100%;
      p {
        line-height: 110px;
      }
    }
    .wedo-main-2-main-left-2 {
      text-align: left;
      font-size: 20px;
      margin-left: 40px;
      p {
        line-height: 30px;
      }
    }
    .wedo-main-2-main-1 {
      width: 100%;
      height: 110px;
      background: url(../assets/customer.png) no-repeat center 100%;
      margin-bottom: 30px;
      position: relative;
    }
    .wedo-main-2-main-2::after {
      content: "";
      display: block;
      width: 30px;
      height: 30px;
      background: url(../assets/jiantou.png) no-repeat center 100%;
      position: absolute;
      top: 150px;
      left: 50%;
      transform: translateX(-50%);
    }
    .wedo-main-2-main-3-1::after,
    .wedo-main-2-main-3-2::after {
      content: "";
      display: block;
      width: 30px;
      height: 30px;
      background: url(../assets/jiantou.png) no-repeat center 100%;
      position: absolute;
      top: -30px;
      left: 50%;
      transform: translateX(-50%);
    }
    .wedo-main-2-main-2 {
      width: 100%;
      height: 110px;
      background: url(../assets/testing.png) no-repeat center 100%;
      margin-bottom: 30px;
    }
    .wedo-main-2-main-3 {
      width: 100%;
      height: 270px;
      .wedo-main-2-main-3-1 {
        float: left;
        height: 100%;
        width: 290px;
        margin-right: 30px;
        background: url(../assets/resale.png) no-repeat center 100%;
        position: relative;
      }
      .wedo-main-2-main-3-2 {
        float: right;
        height: 100%;
        width: calc(100% - 320px);
        background: url(../assets/endof.png) no-repeat center 100%;
        position: relative;
      }
    }
  }
}
.wedo-main {
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  left: 340px;
  .wedo-main-tittle {
    color: #fff;
    font-family: "myFirstFont";
    text-align: left;
    font-size: 18px;
    margin-bottom: 20px;
  }
  .wedo-main-neirong {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 1200px;
    li:nth-child(1) div {
      background: url(../assets/img-1.png) no-repeat center 100%;
    }
    li:nth-child(2) div {
      background: url(../assets/img-2.png) no-repeat center 100%;
    }
    li:nth-child(3) div {
      background: url(../assets/img-3.png) no-repeat center 100%;
    }
    li:nth-child(4) div {
      background: url(../assets/img-4.png) no-repeat center 100%;
    }
    li:nth-child(5) div {
      background: url(../assets/img-5.png) no-repeat center 100%;
    }
    li:nth-child(6) div {
      background: url(../assets/img-6.png) no-repeat center 100%;
    }
    li:nth-child(7) div {
      background: url(../assets/img-7.png) no-repeat center 100%;
    }
    li:nth-child(8) div {
      background: url(../assets/img-8.png) no-repeat center 100%;
    }
    li {
      width: 25%;
      height: 250px;
      background: url(../assets/dan-bg.png) no-repeat center 100%;
      padding: 50px 0;
      box-sizing: border-box;
      p {
        color: #fff;
        font-family: "myFirstFont";
      }
      div {
        width: 100%;
        height: 80px;
        margin-bottom: 40px;
      }
    }
    li.wedo-dan {
      background: url(../assets/shen-bg.png) no-repeat center 100%;
    }
  }
}
.wedo-main-font {
  font-family: "myThridFont";
  width: 500px;
  font-size: 68px;
  font-weight: 300;
  position: absolute;
  color: #fff;
  top: 10%;
  right: 200px;
}
.wedo-left {
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  left: 40px;
  .wedo-left-1 {
    div {
      background: url(../assets/com.png) no-repeat center 100%;
      width: 100%;
      height: 40px;
      margin-bottom: 30px;
    }
  }
  .wedo-left-2 {
    div {
      background: url(../assets/banshou.png) no-repeat center 100%;
      width: 100%;
      height: 40px;
      margin-bottom: 30px;
    }
  }
  .wedo-left-3 {
    div {
      background: url(../assets/yiliao.png) no-repeat center 100%;
      width: 100%;
      height: 40px;
      margin-bottom: 30px;
    }
  }
  li.active {
    color: #fff;
    background: url(../assets/bg-active.png) no-repeat center 100%;
    border-left: 6px solid #153d7a;
  }
  li:hover {
    color: #fff;
    background: url(../assets/bg-active.png) no-repeat center 100%;
    border-left: 6px solid #153d7a;
  }
  li {
    color: #777985;
    padding-bottom: 30px;
    padding-top: 50px;
    box-sizing: border-box;
    width: 270px;
    height: 180px;
    cursor: pointer;
  }
}
.wedo {
  background: url(../assets/wedo-bg.png) no-repeat center 100%;
  background-size: 100% 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.right-enter,
.right-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.right-enter-to,
.right-leave {
  opacity: 1;
  transform: translateY(0px);
}
.right-enter-active,
.right-leave-active {
  transition: all 2s;
}
.left-enter,
.left-leave-to {
  opacity: 0;
  margin-top: 30px;
}
.left-enter-to,
.left-leave {
  opacity: 1;
  margin-top: 0px;
}
.left-enter-active,
.left-leave-active {
  transition: all 2s;
}
.el-menu-vertical-demo.el-menu {
  position: absolute;
  z-index: 11;
  background: transparent;
  right: 100px;
  bottom: 0;
  border: 0;
  text-align: left;
  border-left: 1px solid #164b9f;
  padding-bottom: 180px;
  .el-menu-item {
    color: #8a94af !important;
    font-size: 30px;
    font-family: "myFirstFont";
    margin-bottom: 60px;
    position: relative;
  }
  .el-menu-item.is-active::before {
    content: "";
    height: 100%;
    display: block;
    position: absolute;
    left: -8px;
    border-left: 8px solid #164b9f;
  }
  .el-menu-item.is-active {
    color: #fff !important;
  }
  .el-menu-item:focus,
  .el-menu-item:hover {
    background: transparent;
    color: #fff !important;
  }
}

.homes-bottom {
  display: none;
}

@media (max-width: 800px) {
  .tittle-main-3x{
    font-size: 2vh;
  }
  .wedo-main-3-bg {
    width: 100%;
    height: 55%;
    background: url(../assets/wedo-bg3-x.png) no-repeat center 100%;
    position: absolute;
    bottom: 0;
    transform: none;
    top: initial;
    right: 0;
  }
  .wedo-main-3-font-1 {
    font-family: "mySecondFont";
    margin-top: 1.5vh;
  }
  .wedo-main-3-font {
    width: 90%;
    height: 45%;
    background: url(../assets/medical-font-bg.png) no-repeat center 100%;
    background-size: 100% 100%;
    position: absolute;
    top: 10%;
    transform: none;
    left: 50%;
    transform: translateX(-50%);
    font-family: "myFirstFont";
    text-align: left;
    color: #fff;
    padding: 3.5vh;
    box-sizing: border-box;
    font-size: 1.5vh;
    line-height: 2vh;
    padding-top: 14vh;
  }
  .wedo-main-2 {
    position: absolute;
    top: 10%;
    transform: none;
    left: 5%;
    color: #fff;
    font-family: "myFirstFont";
    width: 90%;
    .wedo-main-tittle {
      color: #fff;
      font-family: "myFirstFont";
      text-align: left;
      font-size: 2vh;
      margin-bottom: 3vh;
      background: url(../assets/wodo-2bg.png) no-repeat center 100%;
      background-size: 100% 100%;
      padding: 2vh;
      padding-top: 20vh;
    }
    .wedo-main-2-main {
      width: 100%;
      height: inherit;
      .wedo-main-2-main-right {
        float: initial;
        height: initial;
        text-align: left;
        font-size: 2vh;
        margin-left: 2vh;
        line-height: 3vh;
        width: initial;
      }
      .wedo-main-2-main-right-1 {
        text-align: left;
        font-size: 2vh;
        margin-left: 2vh;
        line-height: 3vh;
        width: 100%;
      }
      .wedo-main-2-main-left {
        float: left;
        text-align: left;
        font-size: 1.5vh;
        height: initial;
        padding: 0;
        p {
          line-height: 1.5vh;
          margin-left: 2vh;
        }
      }
      .wedo-main-2-main-left-1 {
        float: initial;
        text-align: left;
        font-size: 1.5vh;
        height: initial;
        p {
          line-height: 1.5vh;
          margin-left: 2vh;
        }
      }
      .wedo-main-2-main-left-2 {
        text-align: left;
        font-size: 1.5vh;
        margin-left: 0;
        p {
          line-height: 1.5vh;
          margin-left: 2vh;
        }
      }
      .wedo-main-2-main-1 {
        width: 100%;
        height: 9vh;
        background: url(../assets/customer.png) no-repeat center 100%;
        background-size: 100% 100%;
        margin-bottom: 3vh;
        position: relative;
        padding-top: 1.5vh;
      }
      .wedo-main-2-main-2::after {
        content: "";
        display: block;
        width: 3vh;
        height: 3vh;
        background-size: 100% 100%;
        top: -3vh;
        left: 50%;
      }
      .wedo-main-2-main-3-1::after,
      .wedo-main-2-main-3-2::after {
        content: "";
        display: block;
        width: 3vh;
        height: 3vh;
        background-size: 100% 100%;
        top: -3vh;
        left: 50%;
      }
      .wedo-main-2-main-2 {
        width: 100%;
        height: 9vh;
        background: url(../assets/testing.png) no-repeat center 100%;
        background-size: 100% 100%;
        margin-bottom: 3vh;
        position: relative;
        padding-top: 1.5vh;
      }
      .wedo-main-2-main-3 {
        width: 100%;
        height: 18vh;
        .wedo-main-2-main-3-1 {
          float: left;
          height: 100%;
          width: calc(40% - 0.75vh);
          margin-right: 1.5vh;
          background: url(../assets/resale.png) no-repeat center 100%;
          position: relative;
          padding-top: 2vh;
          background-size: 100% 100%;
        }
        .wedo-main-2-main-3-2 {
          float: right;
          height: 100%;
          width: calc(60% - 0.75vh);
          background: url(../assets/endof.png) no-repeat center 100%;
          background-size: 100% 100%;
          position: relative;
          padding: 2vh 1vh;
        }
      }
    }
  }
  .wedo-main-font {
    font-family: "myThridFont";
    width: initial;
    font-size: 5vh;
    font-weight: 300;
    position: absolute;
    color: #fff;
    top: 14%;
    right: 10%;
    z-index: 21;
  }
  .wedo-main {
    position: absolute;
    top: 10%;
    transform: translateX(-50%);
    left: 50%;
    width: 90%;
    background: url(../assets/wodo-1bg.png) no-repeat center 100%;
    background-size: 100% 100%;
    .wedo-main-tittle {
      color: #fff;
      font-family: "myFirstFont";
      text-align: left;
      font-size: 1.5vh;
      padding: 0 3vh;
      margin-top: 15vh;
      margin-bottom: 0;
    }
    .wedo-main-neirong {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      width: 100%;
      li {
        width: 50%;
        height: 15vh;
        background: url(../assets/dan-bg.png) no-repeat center 100%;
        padding: 1.5vh 0.5vh;
        box-sizing: border-box;
        p {
          color: #fff;
          font-family: "myFirstFont";
          font-size: 1vh;
        }
        div {
          margin-bottom: 1vh;
          width: 100%;
          height: 6vh;
          background-size: 15% !important;
        }
      }
      li.wedo-dan {
        background: none;
      }
    }
  }
  .homes-bottom {
    position: absolute;
    bottom: 3%;
    transform: translateX(-50%);
    left: 50%;
    width: 5vh;
    height: 5vh;
    background: url(../assets/next_arro.png) no-repeat center 100%;
    background-size: 100%;
    display: block;
  }

  .el-menu-vertical-demo,
  .wedo-left {
    display: none;
  }
}
</style>
